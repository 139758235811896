import React, { useState, useRef } from 'react';
import CustomBreadcrumb from '../../components/CustomBreadcrumb';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { parsePhoneNumber } from '../../utils/libphonenumber-js.custom';

const Returns = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;
	const [formState, setFormState] = useState('');
	const [hearingAidError, setHearingAidError] = useState('');
	const [phoneInputError, setPhoneInputError] = useState('');
	const reasonRadio1 = useRef(null);
	const reasonRadio2 = useRef(null);
	const reasonRadio3 = useRef(null);
	const shippingRadio1 = useRef(null);
	const shippingRadio2 = useRef(null);
	const leftHearingAidInput = useRef(null);
	const rightHearingAidInput = useRef(null);
	const nameInput = useRef(null);
	const phoneInput = useRef(null);
	const emailInput = useRef(null);
	const messageInput = useRef(null);

	const formatDate = (date) => {
		return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = {
			reason: [reasonRadio1.current, reasonRadio2.current, reasonRadio3.current].find((input) => input.checked).value,
			shipping: [shippingRadio1.current, shippingRadio2.current].find((input) => input.checked).value,
			leftHearingAid: leftHearingAidInput.current.checked,
			rightHearingAid: rightHearingAidInput.current.checked,
			name: nameInput.current.value,
			phone: phoneInput.current.value,
			email: emailInput.current.value,
			message: messageInput.current.value.trim(),
			date: formatDate(new Date())
		};

		// Validator for checkbox inputs
		if (!leftHearingAidInput.current.checked && !rightHearingAidInput.current.checked) {
			setHearingAidError('is-warning');
			return;
		}

		// Validator for Phone Number
		if (!parsePhoneNumber(phoneInput.current.value, 'US').isValid()) {
			setPhoneInputError('is-warning');
			return;
		}

		setFormState('is-processing');

		fetch('https://return-form.audibene.workers.dev', {
			method: 'POST',
			body: JSON.stringify(formData),
			headers: {
				'Content-type': 'application/json; charset=UTF-8'
			}
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(({ confirmationNumber }) => {
				if (!confirmationNumber) {
					throw new Error('No Confirmation number');
				}
				window.location.href = `/faqs/returns-success/?confirmation_number=${confirmationNumber}&ship=${formData.shipping}`;
			})
			.catch((error) => {
				console.error(error);
				setFormState('is-error');
			});
	};

	return (
		<Layout>
			<Seo title="Returns" description="Everything you need to know about returning your hear.com hearing aids. We are committed to 100% customer satisfaction." />
			<div className="o-row-4p5">
				<section className="c-returns-hero">
					<div className="o-wrapper o-wrapper--small">
						<div className="o-row-2">
							<h1 className="c-returns-title">
								<span className="u-color-secondary">Returning</span> your <br /> hearing aids
							</h1>
						</div>
						<div className="o-row-2">
							<img
								className="c-returns-box"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/returns/box.svg"
								alt="box"
								loading="lazy"
							/>
						</div>
						<div className="o-row-2">
							<p className="c-returns-inst">Return Instructions</p>
						</div>
						<img
							className="c-returns-caret"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/returns/caret.svg"
							alt="downward caret"
							loading="lazy"
						/>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<div className="o-wrapper o-wrapper--small">
					<div className="o-row-2p5">
						<CustomBreadcrumb location={location} crumbs={crumbs} />
					</div>
					<div className="o-row-2">
						<h2 className="c-returns-headline">hear.com is committed to 100% satisfaction!</h2>
					</div>
					<div className="o-row-2">
						<p className="c-returns-copy">
							If you are unsatisfied for any reason, you may return your hearing aids within your{' '}
							<strong>45 day </strong>
							trial period.
						</p>
					</div>
					<div className="o-row-2">
						<ul className="c-returns-usps">
							<li>
								<strong className="u-text-italic">We do not accept</strong> any returns after your{' '}
								<strong>45 day </strong>
								trial period has ended.
							</li>
							<li>Financing contract will only be canceled once all hear.com equipment is returned.</li>
							<li>An email will be sent once equipment is received and in return financing is refunded.</li>
						</ul>
					</div>
					<div className="o-row-2">
						<p className="c-returns-copy">
							<strong>
								You will be called by your hear.com representative for next steps. In the meantime, please provide the
								following information:
							</strong>
						</p>
					</div>
					<div className="o-row-2">
						<form className={`c-returns-form ${formState ? formState : ''}`} onSubmit={handleSubmit}>
							<div className="o-row-2">
								<div className="c-returns-form-group">
									<div className="c-returns-form-group__title">
										<p>Reason for return:</p>
									</div>
									<label className="c-returns-form-group__option">
										<span className="c-returns-form-group__label">Price</span>
										<input ref={reasonRadio1} type="radio" name="reason" value="price" required />
										<span className="c-returns-form-group__option-radio"></span>
									</label>
									<label className="c-returns-form-group__option">
										<span className="c-returns-form-group__label">Physical Discomfort</span>
										<input ref={reasonRadio2} type="radio" name="reason" value="discomfort" />
										<span className="c-returns-form-group__option-radio"></span>
									</label>
									<label className="c-returns-form-group__option">
										<span className="c-returns-form-group__label">Customer Service Issues</span>
										<input ref={reasonRadio3} type="radio" name="reason" value="service" />
										<span className="c-returns-form-group__option-radio"></span>
									</label>
								</div>
							</div>
							<div className="o-row-2">
								<div className="c-returns-form-group">
									<div className="c-returns-form-group__title">
										<p>
											Item(s) to be returned: <span className="c-returns-standout-text">at least one is required</span>
										</p>
									</div>
									<div className="c-returns-form-group__two-col">
										<label className={`c-returns-form-group__checkbox ${hearingAidError ? hearingAidError : ''}`}>
											Left hearing aid
											<input type="checkbox" name="leftHearingAid" ref={leftHearingAidInput} />
											<span className="c-returns-form-group__checkbox-checkmark"></span>
										</label>
										<label className={`c-returns-form-group__checkbox ${hearingAidError ? hearingAidError : ''}`}>
											Right hearing aid
											<input type="checkbox" name="rightHearingAid" ref={rightHearingAidInput} />
											<span className="c-returns-form-group__checkbox-checkmark"></span>
										</label>
									</div>
								</div>
							</div>
							<div className="o-row-3p5">
								<div className="o-row-2">
									<p className="c-returns-form-question">How would you like to return your equipment?</p>
								</div>
								<div className="o-row">
									<div className="c-returns-form-ship">
										<div className="c-returns-form-ship-col">
											<label className="c-returns-form-ship-opt">
												<span className="c-returns-form-ship-opt__label">UPS pickup</span>
												<input ref={shippingRadio1} type="radio" name="shipping" value="pickup" required />
												<span className="c-returns-form-ship-opt-bg"></span>
											</label>
											<p className="c-returns-form-ship-copy">
												hear.com will schedule a UPS pickup{' '}
												<strong className="u-text-italic">and will inform you via text message.</strong>
												<strong className="u-text-italic">We will schedule your pickup the next business day</strong>
												(up to 3 attempts total) – it is acceptable to leave package on doorstep.
											</p>
										</div>
										<div className="c-returns-form-ship-col">
											<label className="c-returns-form-ship-opt">
												<span className="c-returns-form-ship-opt__label">UPS dropoff</span>
												<input ref={shippingRadio2} type="radio" name="shipping" value="dropoff" />
												<span className="c-returns-form-ship-opt-bg"></span>
											</label>
											<p className="c-returns-form-ship-copy">
												Save/place your UPS return label as a PDF, place on the package and drop off at your local UPS
												store and/or drop off location
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="o-row-2">
								<div className="o-row-2">
									<div className="c-returns-form-info-row">
										<label htmlFor="name" className="c-returns-form-info-label">
											Name
										</label>
										<input
											type="text"
											id="name"
											name="name"
											placeholder="name"
											className="c-returns-form-info-input"
											ref={nameInput}
											required
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-returns-form-info-row">
										<label htmlFor="phone" className="c-returns-form-info-label">
											Phone
										</label>
										<input
											ref={phoneInput}
											type="tel"
											id="phone"
											name="phone"
											placeholder="+1"
											className={`c-returns-form-info-input ${phoneInputError ? phoneInputError : ''}`}
											required
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-returns-form-info-row">
										<label htmlFor="email" className="c-returns-form-info-label">
											Email
										</label>
										<input
											type="email"
											id="email"
											name="email"
											placeholder="email"
											className="c-returns-form-info-input"
											ref={emailInput}
											required
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-returns-form-info-row">
										<label htmlFor="email" className="c-returns-form-info-label">
											Message
										</label>
										<textarea
											type="text"
											id="message"
											name="message"
											placeholder="Optional Message"
											className="c-returns-form-info-input"
											ref={messageInput}
										></textarea>
									</div>
								</div>
								<div className="o-row-2 u-text-center">
									<button className="c-returns-submit" type="submit" value="Submit">
										<span className="c-returns-submit-copy">Submit</span>
										<div className="c-returns-submit-loader"></div>
									</button>
									<p className="c-returns-error">
										Something went wrong! Please try again later or call us at (888) 780-3450
									</p>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Returns;
